import React, { useEffect } from "react";
import Header from "../components/header";
import Footer1 from "../components/footer-1";
import { createTask, getPrintfulTask } from "../services/apiCalls";
import { navigate, withPrefix } from "gatsby";
import { isLoggedIn } from "../services/auth";
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { postStoreProducts, selectStoresState, resetProductsToPublishStore } from "../sagas/stores/storesSlice";

export default function PublishingStore() {
  const dispatch = useDispatch();
  const { isPostingStoreProductsSuccess, productsToPublishStore, bulkEditOption, selectedUserStoreCollection, selectedUserStore } = useSelector(selectStoresState);

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/signin");
      return;
    }

    const allItems = JSON.parse(localStorage.getItem("allItems") || "[]");

    if (productsToPublishStore.length === 0 || allItems.length === 0) navigate("/beta");

    function waitFiveSeconds() {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, 5000);
      });
    }

    const handleCreateTask = async(image, logo, name) => {
      let completed = false;
      let count = 0;
      let taskRes;
  
      while (!completed && count < 10) {
        if (count > 0) await waitFiveSeconds()
        taskRes = await createTask({
          image,
          logo,
          name,
        });
        if (taskRes.status == "success") {
          completed = true;
        } else {
          count++;
        }
      }
  
      return taskRes;
    }

    const getPrintfulData = async(task_key) => {
      let completed = false;
      let count = 0;
      let printfulRes;
  
      while (!completed && count < 10) {
        await waitFiveSeconds()
        printfulRes = await getPrintfulTask(task_key, 'no');
        if (printfulRes.result?.status === "completed") {
          completed = true;
        } else {
          count++;
        }
      }
  
      return printfulRes?.result?.mockups;
    }

    const publishNewProductsToStore = async() => {
      try {
        const returnResults = await Promise.all(
          productsToPublishStore.map(async(product) => {
            const item = allItems.find((item) => item.key === product.key);

            if (!!item.value?.images?.length) {
              const images = item.value.images.map((image, index) => {
                return {
                  position: index + 1,
                  src: image,
                }
              });

              return {
                type: product.key,
                tagId: selectedUserStoreCollection.tagId,
                images,
                original: item.thumbnail,
                logo: item.logo,
                collectionName: selectedUserStoreCollection.title,
              };
            }
            
            const taskRes = await handleCreateTask(item.upscaled, item.logo, product.key.split('_')[1]);
            const { result } = taskRes.data.que;
            const task_key = result.task_key;

            const mockups = await getPrintfulData(task_key);

            const images = mockups.map((mockup, index) => {
              return {
                position: index + 1,
                src: mockup.mockup_url,
              }
            });

            return {
              type: product.key,
              tagId: selectedUserStoreCollection.tagId,
              images,
              original: item.thumbnail,
              logo: item.logo,
              collectionName: selectedUserStoreCollection.title,
            };
          })
        );

        dispatch({
          type: postStoreProducts.type,
          payload: {
            storeId: selectedUserStore.id,
            collectionId: selectedUserStoreCollection.id,
            collectionTagId: selectedUserStoreCollection.tagId,
            products: returnResults,
            bulkEditOption,
          }
        });
      } catch (error) {
        //swal open
        Swal.fire({
          icon: 'error',
          text: 'Something went wrong. Please try again.',
          confirmButtonText: 'Retry',
        }).then(async(result) => {
          if (result.isConfirmed) publishNewProductsToStore();
        })
      }
    }
    
    if (productsToPublishStore.length && allItems.length && selectedUserStore.id && selectedUserStoreCollection.tagId) publishNewProductsToStore();
  }, [])

  useEffect(() => {
    if (isPostingStoreProductsSuccess) {
      dispatch(resetProductsToPublishStore());
      navigate("/publishing-store-success");
    }
  }, [isPostingStoreProductsSuccess])

  return (
    <>
      <Header />
      <main className="content-main">
        <div className="store-cont">
          <div className="container">
            <div className="inner-cont">
              <div className="figure">
                <img
                  src={withPrefix("assets/img/green-little-balls.gif")}
                  alt="storefront-gif"
                  width={186}
                  height="100%"
                />
              </div>
              <h1>Generating your Storefront...</h1>
              <div className="box" style={{ maxWidth: 546 }}>
                We're on it! Feel free to exit this page. 
                We'll notify you by email as soon as your store is ready. 
                You can also access it conveniently from My Storefronts. 
                Please note that any updates may take up to 60 seconds to synchronize across our network. 
                If you don't see the changes right away, simply give the page a quick refresh. 
                <br />
                <a href="#" onClick={() => navigate("/my-storefronts")}>My Storefronts</a>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer1 />
    </>
  );
};
